import {Component, OnInit, OnDestroy} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {timer, Subscription, Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Candy Mountain.io';

  timerStart: number;
  secondsHere: number;
  timer: number;
  timer$: Observable<unknown>;
  timerSub: Subscription;

  constructor(
    private afs: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    this.timer$ = timer(1, 1);
    this.timerSub = this.timer$.subscribe(() => {
      const currentTime = Math.round((new Date()).getTime());
      this.timerStart ? this.timer = currentTime : this.timerStart = currentTime;
      this.secondsHere = this.timer - this.timerStart;
    });
  }

  // click(objectClicked: string): void {
  //   this.afs.collection('clicks').add({
  //     timeFromStartToClick: this.timer - this.timerStart,
  //     time: this.timer,
  //     objectClicked: objectClicked
  //   });
  // }

  ngOnDestroy(): void {
    this.timerSub.unsubscribe();
  }
}

