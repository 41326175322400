import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AngularFireModule} from '@angular/fire';

import {AppComponent} from './app.component';
import {AngularFirestoreModule} from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp({
        apiKey: 'AIzaSyDOX8LYeuxuBgO0ydl2vHlI3ur89D-Tp84',
        authDomain: 'candy-mountain-io.firebaseapp.com',
        databaseURL: 'https://candy-mountain-io.firebaseio.com',
        projectId: 'candy-mountain-io',
        storageBucket: 'candy-mountain-io.appspot.com',
        messagingSenderId: '758950975194'
      }
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
